export const Language = [
    {
        label: 'Please Select',
        value: ''
    },
    {
        label: 'English',
        value: 'english'
    },
]

export const radioData = [
    {
        label: 'Static',
        value: '1'
    },
    {
        label: 'Value 2',
        value: '2'
    },
    {
        label: 'Value 3',
        value: '3'
    },
]