import React from "react";
import { Typography, Box } from "@mui/material";

const NotepadPage = () => {
  return (
    <Box>
      <Typography variant="h5">Notepad</Typography>
    </Box>
  );
};
export default NotepadPage;
