import React from "react";
import { Typography, Box } from "@mui/material";

const HelpPage = () => {
  return (
    <Box>
      <Typography variant="h5">Help</Typography>
    </Box>
  );
};
export default HelpPage;
